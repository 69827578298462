.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  min-width: 125px;
  min-height: 37px;
  border-radius: 50px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  font-family: "Inter";

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;

  transition: all 0.5s ease-in-out;
  padding: 0 20px;

  &.fullwidth {
    width: 100%;
  }
}

.big {
  min-width: 175px;
  min-height: 58px;
}
.medium {
  min-width: 125px;
  min-height: 37px;
}
.small {
  min-width: 72px;
  min-height: 27px;
}

.primary {
  background: #fd4367;
  color: white;
  &:hover {
    background: #7d42fb;
  }
}

.seccondary {
  border: 1px solid rgba(32, 30, 87, 0.3);
  color: #201e57;
  &.withoutBorder {
    border: none;
  }
  &:hover {
    border: 1px solid #7d42fb;
    color: #7d42fb;
    &.withoutBorder {
      border: none;
    }
  }
}
