.header {
  //display: flex;
  //flex-direction: row;
  //align-items: center;
  //align-content: center;
  //justify-content: space-between;
  padding: 0px 22px;
  gap: 10px;

  max-width: 1280px;
  min-height: 92px;

  width: 100%;
  height: 100%;

  /* glass */

  background: rgba(255, 255, 255, 0.7);
  /* Drop Shadow */

  box-shadow: 0px 12px 28px rgba(0, 0, 0, 0.05);
  border-radius: 50px;
  margin: 0 auto 0 auto;
  flex-wrap: wrap;
  @media screen and (max-width: 600px) {
    border-radius: 0;
    padding: 20px;
  }
}

.link {
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  min-width: 150px;
  min-height: 58px;
  border-radius: 50px;
  &:hover {
    background-color: #8a8a8a40;
  }
  // @media screen and (max-width: 480px) {
  //   min-width: 150px;
  // }
}

.logo {
  width: 100%;
  max-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // @media screen and (max-width: 480px) {
  //   max-width: 150px;
  // }
  /* Inside auto layout */
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 10px;
  flex-wrap: wrap;
  flex-grow: 1;

  max-width: 300px;
  min-height: 56px;
  cursor: pointer;

  /* Inside auto layout */
}
